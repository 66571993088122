import React from 'react';
import '../css/galleria.css';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion'; 
import { Helmet } from 'react-helmet';
import g1 from '../images/galleria/1.jpg';
import g2 from '../images/galleria/2.jpg';
import g3 from '../images/galleria/3.jpg';
import g4 from '../images/galleria/4.jpg';
import g5 from '../images/galleria/5.jpg';
import g6 from '../images/galleria/6.jpg';
import g7 from '../images/galleria/7.jpg';
import g8 from '../images/galleria/8.jpg';
import g9 from '../images/galleria/9.jpg';
import g10 from '../images/galleria/10.jpg';
import g11 from '../images/galleria/11.jpg';
import g12 from '../images/galleria/12.jpg';
import g13 from '../images/galleria/13.jpg';
import g14 from '../images/galleria/14.jpg';
import g15 from '../images/galleria/15.jpg';
import g16 from '../images/galleria/16.jpg';
import g17 from '../images/galleria/17.jpg';
import g18 from '../images/galleria/18.jpg';
import g19 from '../images/galleria/19.jpg';
import g20 from '../images/galleria/20.jpg';
import g21 from '../images/galleria/21.jpg';
import g22 from '../images/galleria/22.jpg';
import g23 from '../images/galleria/23.jpg';

function Galleria() {
  const images = [
    g1, g2, g3, g4, g5, g6, g7, g8, g9, g10, g11, g12, g13, g14, g15, g16,
    g17, g18, g19, g20, g21, g22, g23
  ];

  return (
    <>
      <Helmet>
        <title>Galleria - Crono Italia</title>
        <meta name="description" content="Esplora la nostra galleria di immagini per rivivere i momenti più belli delle manifestazioni organizzate da Crono Italia." />
        <meta name="keywords" content="galleria, immagini, Crono Italia, eventi, manifestazioni" />
        <meta property="og:title" content="Galleria - Crono Italia" />
        <meta property="og:description" content="Scopri le immagini delle manifestazioni organizzate da Crono Italia. Passione e professionalità in ogni evento." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.crono-italia.it/galleria" />
        <meta property="og:image" content={g1} />
        <meta property="og:image:alt" content="Galleria di immagini di Crono Italia" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Galleria - Crono Italia" />
        <meta name="twitter:description" content="Scopri le immagini delle manifestazioni organizzate da Crono Italia. Passione e professionalità in ogni evento." />
        <meta name="twitter:image" content={g1} />
        <link rel="canonical" href="https://www.crono-italia.it/galleria" />
        <html lang="it" />
        <meta property="og:locale" content="it_IT" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ImageGallery",
              "name": "Galleria - Crono Italia",
              "description": "Galleria di immagini delle manifestazioni organizzate da Crono Italia",
              "url": "https://www.crono-italia.it/galleria",
              "image": [
                "${g1}",
                "${g2}",
                "${g3}"
              ]
            }
          `}
        </script>
      </Helmet>
      <div className='list'>
        {images.map((image, index) => (
          <motion.img
            key={index}
            src={image}
            alt={`Galleria ${index + 1}`}
            className='imgGalleria'
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: index * 0.1 }}
          />
        ))}
      </div>

      <div className='endHome'>
        <div className='textEnd'>
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Vuoi chiederci qualcosa?
          </motion.h3>
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            Siamo qui per rispondere a tutte le tue domande!
          </motion.h3>
        </div>

        <motion.div whileHover={{ scale: 1.1 }} transition={{ type: 'spring', stiffness: 300 }}>
          <Link to="/contattaci" className='contactButton' onClick={() => { window.scrollTo(0, 0); }}>
            Clicca qui
          </Link>
        </motion.div>
      </div>
    </>
  );
}

export default Galleria;
