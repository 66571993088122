import '../css/footer.css';
import logo from "../images/logo.png";
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import camep from '../images/club/camep.png';
import classic_car_club_molise from '../images/club/classic_car_club_molise.png';
import gas from '../images/club/gas.png';
import jaguar_drivers_club from '../images/club/jaguar_drivers_club.png';
import la_manovella_fermano from '../images/club/la_manovella_fermano.png';
import leggendary_classic_car_bracciano from '../images/club/leggendary_classic_car_bracciano.png';
import ruote_del_passato from '../images/club/ruote_del_passato.png';
import topolino_club_firenze from '../images/club/topolino_club_firenze.png';
import topolino_club_livorno from '../images/club/topolino_club_livorno.png';
import veteran_car_club_ibleo from '../images/club/veteran_car_club_ibleo.png';

function Footer() {


  return (
    <>
      <h1 className='titleFooter'>Alcuni dei club con cui abbiamo collaborato</h1>
      <div className="scrolling-images-container">
        <ul style={{ '--time': '60s', '--quantity': '8' }}>
          <li style={{"--index": '1'}}>
            <a href='https://www.camep.it/'  target=' '><img src={camep} alt="camep" className='footer-image' /></a>
          </li>
          <li style={{"--index": '2'}}>
            <a href='https://cccmolise.it' target=' '><img src={classic_car_club_molise} alt="classic_car_club_molise" className='footer-image' /></a>
          </li>
          <li style={{"--index": '3'}}>
            <a href='https://www.gasclub.it' target=' '><img src={gas} alt="g.a.s." className='footer-image' /></a>
          </li>
          <li style={{"--index": '4'}}>
            <a href='https://www.jdcitalia.it' target=' '><img src={jaguar_drivers_club} alt="jaguar_drivers_club" className='footer-image' /></a>
          </li>
          <li style={{"--index": '5'}}>
            <a href='https://www.lamanovelladelfermano.it' target=' '><img src={la_manovella_fermano} alt="la_manovella_del_fermano" className='footer-image' /></a>
          </li>
          <li style={{"--index": '6'}}>
            <a href='https://www.legendaryclassiccarsbracciano.it' target=' '><img src={leggendary_classic_car_bracciano} alt="leggendary_classic_car_bracciano" className='footer-image' /></a>
          </li>
          <li style={{"--index": '7'}}>
            <a href='https://ruotedelpassato.org' target=' '><img src={ruote_del_passato} alt="ruote_del_passato" className='footer-image'  /></a>
          </li>
          <li style={{"--index": '8'}}>
            <a href='https://www.topolinoclubfirenze.it' target=' '><img src={topolino_club_firenze} alt="topolino_club_firenze" className='footer-image' /></a>
          </li>
          <li style={{"--index": '9'}}>
            <a href='https://www.topolinoclublivorno.it' target=' '><img src={topolino_club_livorno} alt="topolino_club_livorno" className='footer-image' /></a>
          </li>
          <li style={{"--index": '10'}}>
            <a href='https://vccibleo.it' target=' '><img src={veteran_car_club_ibleo} alt="veteran_car_club_ibleo" className='footer-image' /></a>
          </li>
        </ul>
      </div>

      <hr className='hrFooter'></hr>
      <div className='containerFooter'>
        <span className='logoFooterSpan'><img src={logo} className='logoFooter' alt='logo' /></span>
        <div className='infoFooter'>
          <h3>Crono Italia a.s.d.</h3>
          <h5>info@crono-italia.it</h5>
          <h5>Via Settevalli, 133/0, 06128 Perugia PG</h5>
          <h5>tel +39 347 2217448</h5>
          <h5>codice fiscale: 97793290012</h5>
        </div>
        <div className='infoFooter'>
          <h3>LINK UTILI</h3>
          <Link to="/chi-siamo" className='link'><h5>Chi Siamo</h5></Link>
          <Link to="/contattaci" className='link'><h5>Contattaci</h5></Link>
        </div>
      </div>
      <hr className='hrMainFooter'></hr>

      <div className='copyright'>
        <h4>Copyright © 2025 - Designed by <a href='https://www.giulioborzetta.com' target='_blank'>Giulio Borzetta</a></h4>
      </div>
    </>
  );
}

export default Footer;
