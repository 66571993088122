import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import '../css/contact.css';
import contactPhoto from '../images/contactPhoto.jpg';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';

function Contattaci() {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const sendEmail = (e) => {
    emailjs.init(process.env.REACT_APP_EMAILJS_USER_ID);
    e.preventDefault();
    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      e.target
    ).then((result) => {
        console.log(result.text);
        setIsPopupVisible(true);
        setTimeout(() => {
          setIsPopupVisible(false);
        }, 3000);
    }, (error) => {
        console.log(error.text);
    });
  };

  return (
    <>
      <Helmet>
        <title>Contattaci - Crono Italia</title>
        <meta name="description" content="Contatta Crono Italia per tutte le tue domande o richieste di informazioni. Siamo qui per aiutarti!" />
        <meta name="keywords" content="contattaci, Crono Italia, supporto, informazioni, auto d'epoca" />
        <meta property="og:title" content="Contattaci - Crono Italia" />
        <meta property="og:description" content="Contatta Crono Italia per tutte le tue domande o richieste di informazioni. Siamo qui per aiutarti!" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.crono-italia.it/contattaci" />
        <meta property="og:image" content={contactPhoto} />
        <meta property="og:image:alt" content="Foto di contatto di Crono Italia" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contattaci - Crono Italia" />
        <meta name="twitter:description" content="Contatta Crono Italia per tutte le tue domande o richieste di informazioni. Siamo qui per aiutarti!" />
        <meta name="twitter:image" content={contactPhoto} />
        <link rel="canonical" href="https://www.crono-italia.it/contattaci" />
        <html lang="it" />
        <meta property="og:locale" content="it_IT" />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "ContactPage",
              "name": "Contattaci - Crono Italia",
              "description": "Pagina di contatto per Crono Italia",
              "url": "https://www.crono-italia.it/contattaci",
              "contactPoint": {
                "@type": "ContactPoint",
                "email": "info@crono-italia.it",
                "contactType": "customer service"
              }
            }
          `}
        </script>
      </Helmet>

      <motion.div
        className='contactContainer'
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <img src={contactPhoto} alt='contact photo' className='contactImg' />
        <h1 className='textHeaderUp'>/Contattaci</h1>
        <h1 className='textHeader'>IN QUESTA PAGINA PUOI FARCI TUTTE LE DOMANDE CHE VUOI</h1>
      </motion.div>

      <motion.div
        className="form-container"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.5, duration: 1 }}
      >
        <div>
          <h1 className='white'>Siamo qui per rispondere a tutte le tue domande</h1>
        </div>
        <form onSubmit={sendEmail}>
          <div className="form-group">
            <div className="form-row">
              <div className="col">
                <input type="text" name="name" className="form-control" placeholder="Nome Completo" required />
              </div>
              <div className="col">
                <input type="email" name="email" className="form-control" placeholder="Indirizzo Email" required />
              </div>
            </div>
          </div>
          <div className="form-group">
            <textarea placeholder="Il Tuo Messaggio" className="form-control" name="message" rows="10" required></textarea>
          </div>
          <motion.button
            type="submit"
            className="form-button"
            whileHover={{ scale: 1.1 }} 
            whileTap={{ scale: 0.9 }}   
            transition={{ duration: 0.2 }}
          >
            Invia
          </motion.button>
        </form>
      </motion.div>

      <motion.div
        className='maps'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.8, duration: 1 }}
      >
        <div className='blockMaps'>
          <h1>Dove puoi trovarci?</h1>
          <h3>Via Settevalli, 133/0, 06128 Perugia PG</h3>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d728.3603593263649!2d12.36866092857239!3d43.09523709818836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132ea0c1ffcc0a73%3A0x278a88a339961652!2sVia%20Settevalli%2C%20133%2F0%2C%2006128%20Perugia%20PG!5e0!3m2!1sit!2sit!4v1725376892779!5m2!1sit!2sit"
          width="600"
          height="450"
          style={{ border: '0' }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Maps Embed"
        ></iframe>
      </motion.div>

      <div className='divDo'>
  <motion.hr
    className='hrDo'
    initial={{ width: 0 }}
    animate={{ width: '100%' }}
    transition={{ duration: 1 }}
  />

  <motion.div
    className='mainDo'
    initial={{ opacity: 0 }}
    whileInView={{ opacity: 1 }}
    transition={{ duration: 1 }}
  >
    <motion.div
      className='colDo'
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6 }}
    >
      <h1>20+</h1>
      <h3>anni di esperienza</h3>
    </motion.div>

    <motion.div
      className='colDo'
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.3, duration: 0.6 }}
    >
      <h1>500+</h1>
      <h3>manifestazioni fatte</h3>
    </motion.div>

    <motion.div
      className='colDo'
      initial={{ opacity: 0, y: 50 }}
      whileInView={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.6, duration: 0.6 }}
    >
      <h1>40+</h1>
      <h3>cronometristi</h3>
    </motion.div>
  </motion.div>
</div>

      <div className='endHome'>
        <div className='textEnd'>
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
          >
            Vuoi vedere le manifestazioni che abbiamo fatto?
          </motion.h3>
          <motion.h3
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1, delay: 0.2 }}
          >
            Clicca sul pulsante!
          </motion.h3>
        </div>
        <motion.div
          whileHover={{ scale: 1.1 }}
        >
            <Link to="/manifestazioni" className='contactButton' onClick={() => { window.scrollTo(0, 0); }} >Clicca qui</Link>
        </motion.div>
      </div>

      {isPopupVisible && (
        <motion.div
          className="popup"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
        >
          <p>Email inviata con successo!</p>
        </motion.div>
      )}
    </>
  );
}

export default Contattaci;
