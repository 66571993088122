import React from 'react';
import '../css/chiSiamo.css';
import { Link } from 'react-router-dom';
import chiSiamo from '../images/chiSiamoPhoto.jpg';
import chiSiamo1 from '../images/chiSiamo/chisiamo1.jpg'; 
import chiSiamo2 from '../images/chiSiamo/chisiamo2.jpg'; 
import chiSiamo3 from '../images/chiSiamo/chisiamo3.jpg'; 
import chiSiamo4 from '../images/chiSiamo/chisiamo4.jpg'; 
import { motion } from 'framer-motion'; 
import { Helmet } from 'react-helmet';

function ChiSiamo() {
  return (
  <>
        <Helmet>
        <title>Chi Siamo - Crono Italia</title>
        <meta name="description" content="Scopri la storia e i valori di Crono Italia, un'organizzazione leader nel settore delle auto d'epoca e del cronometraggio in Italia." />
        <meta name="keywords" content="Crono Italia, auto d'epoca, cronometraggio, organizzazione eventi, passione per il tempo" />
        <meta property="og:title" content="Chi Siamo - Crono Italia" />
        <meta property="og:description" content="Scopri la storia e i valori di Crono Italia, un'organizzazione leader nel settore delle auto d'epoca e del cronometraggio in Italia." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.crono-italia.it/chi-siamo" />
        <meta property="og:image" content={chiSiamo} />
        <meta property="og:image:alt" content="Foto rappresentativa di Crono Italia" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chi Siamo - Crono Italia" />
        <meta name="twitter:description" content="Scopri la storia e i valori di Crono Italia, un'organizzazione leader nel settore delle auto d'epoca e del cronometraggio in Italia." />
        <meta name="twitter:image" content={chiSiamo} />
        <link rel="canonical" href="https://www.crono-italia.it/chi-siamo" />
      </Helmet>
    <motion.div 
      className='contactContainer'
      initial={{ opacity: 0, y: -50 }} 
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1 }}
    >
      <img src={chiSiamo} alt='Chi Siamo Photo' className='contactImg'/>
      <h1 className='textHeaderUp'>/chi-siamo</h1>
      <h1 className='textHeader'>Chi Siamo?</h1>
    </motion.div>

    <motion.h1 
      className='titleChiSiamo' 
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 1, delay: 0.2 }}
    >
      LA NOSTRA STORIA
    </motion.h1>

    <div className='containerChiSiamo'>
      <motion.div 
        className='imgs1'
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, delay: 0.4 }}
      >
        <img src={chiSiamo2} alt="" className='imgChiSiamo'/>
        <img src={chiSiamo1} alt="" className='imgChiSiamo'/>
      </motion.div>

      <motion.div 
        className='chiSiamoText'
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1, delay: 0.6 }}
      >
        <p><strong>L’A.S.D. Crono Italia</strong> è l'evoluzione naturale di un'esperienza ultra-decennale maturata sotto il nome di <strong>Asi Crono</strong>, un'organizzazione che, nel corso degli anni, ha contribuito in maniera significativa alla crescita e alla diffusione delle manifestazioni legate al mondo delle auto storiche e d'epoca. Grazie alla passione e alla dedizione dei suoi membri, il gruppo ha saputo ritagliarsi un ruolo di rilievo nel panorama italiano, diventando un punto di riferimento per appassionati e professionisti del settore.</p>
        <p>Con il passare degli anni l'Associazione ha continuato a crescere in dimensioni e professionalità, estendendo la sua sfera d'azione a tutto il territorio nazionale e ottimizzando il proprio materiale mantenendolo sempre "allo stato dell'arte". </p>
        <br />
        <p>I membri del team hanno accumulato un prezioso bagaglio di esperienze grazie a un costante impegno sul campo, guadagnando la fiducia e il rispetto delle associazioni e degli organizzatori di eventi automobilistici.</p>
        <p>Oggi, <strong>Crono Italia</strong> è in grado di rispondere efficacemente alle richieste di qualsiasi associazione o ente, offrendo servizi di cronometraggio di alta qualità e una competenza che abbraccia ogni aspetto dell'organizzazione di eventi e raduni di auto storiche ideando schema prove "su misura" e predisponendo Road-Book su indicazione dei Club organizzatori.</p>
        <br />
        <p>Il gruppo è unito da due elementi fondamentali: la <strong>passione per il tempo</strong> e per le <strong>auto d'epoca</strong>.</p>
        <p>Il tempo, che nelle manifestazioni diventa un parametro cruciale e decisivo, rappresenta non solo una misura tecnica per i "Krono", ma un simbolo di precisione, rispetto e dedizione. Le auto d'epoca incarnano invece la passione comune che tiene insieme il gruppo. Ogni membro, con la sua esperienza e competenza, contribuisce a rendere <strong>Crono Italia</strong> un'organizzazione unica nel suo genere, in grado di combinare tecnologia avanzata e amore per le tradizioni.</p>
        <br />
        <hr />
        <br />
        <p>Grazie a una rete di collaboratori diffusa in ogni regione italiana, <strong>Crono Italia</strong> è in grado di assicurare la propria presenza e competenza ovunque ci sia richiesta, mantenendo standard qualitativi elevati e un'attenzione particolare per i dettagli.</p>
      </motion.div>

      <motion.div 
        className='imgs2'
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1, delay: 0.8 }}
      >
        <img src={chiSiamo3} alt="" className='imgChiSiamo'/>
        <img src={chiSiamo4} alt="" className='imgChiSiamo'/>
      </motion.div>
    </div>

    <div className='endHome'>
      <div className='textEnd'>
        <motion.h3
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Vuoi chiederci qualcosa?
        </motion.h3>
        <motion.h3
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.2 }}
        >
          Siamo qui per rispondere a tutte le tue domande!
        </motion.h3>
      </div>

      <motion.div
        whileHover={{ scale: 1.1 }}
        transition={{ type: "spring", stiffness: 300 }}
      >
        <Link to="/contattaci" className='contactButton' onClick={() => { window.scrollTo(0, 0); }}>Clicca qui</Link>
      </motion.div>
    </div>
  </>
  );
}

export default ChiSiamo;
